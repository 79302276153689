import { ComponentProps } from 'react';
import { StarRating as RooStarRating } from '@qga/roo-ui/components';
import { RATING_MESSAGES } from '../../constants/starRating';

import Tooltip from '../Tooltip';
import VisuallyHidden from '../VisuallyHidden';
import isNumber from 'lodash/isNumber';
import useUUID from '../../hooks/useUUID';

interface StarRatingProps extends ComponentProps<typeof RooStarRating> {}

const StarRating = ({
  dataTestId,
  rating,
  ratingType,
  size,
}: StarRatingProps) => {
  const title = !!(isNumber(rating) || rating)
    ? `${rating} out of 5 rating`
    : 'No rating';
  const titleId = useUUID('star-');

  return (
    <Tooltip
      triggerProps={{ 'aria-labelledby': titleId }}
      triggerElement={
        <>
          <VisuallyHidden id={titleId}>{title}</VisuallyHidden>
          <RooStarRating
            data-testid={dataTestId}
            rating={rating}
            ratingType={ratingType}
            size={size}
            excludeTitle
          />
        </>
      }
      tooltipContent={
        <Tooltip.Text>
          {RATING_MESSAGES[ratingType as keyof typeof RATING_MESSAGES]}
        </Tooltip.Text>
      }
    />
  );
};

export default StarRating;
