import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useUUID = (prefix?: string) => {
  const idRef = useRef<string | undefined>();
  if (!idRef.current) {
    idRef.current = `${prefix ?? ''}${uuidv4()}`;
  }
  return idRef.current;
};

export default useUUID;
