const AAA_MESSAGE =
  'This official star rating is provided by the property, verified by an independent third-party.*';

const SELF_RATED_MESSAGE =
  'This is a self-assigned rating by the service provider or obtained through Expedia.*';

export const RATING_MESSAGES = {
  AAA: AAA_MESSAGE,
  SELF_RATED: SELF_RATED_MESSAGE,
};
