import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Text } from '@qga/roo-ui/components';

const TooltipText = styled(Text)`
  color: ${themeGet('components.tooltip.text.color')};
  font-size: ${themeGet('components.tooltip.text.fontSize')};
  font-family: ${themeGet('components.tooltip.text.fontFamily')};
  line-height: ${themeGet('components.tooltip.text.lineHeight')};
`;

export default TooltipText;
