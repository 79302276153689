import PropTypes from 'prop-types';
import Image from '../shared/propTypes/Image';

export default {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  facilities: PropTypes.arrayOf(PropTypes.string),
  mainImage: PropTypes.shape(Image),
  maxOccupants: PropTypes.number,
};
