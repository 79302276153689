export function reset() {
  this.reset();
}

export default {
  push: (input) => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push(reset);
    window.dataLayer.push(input);
  },
};
