const userInteractionEvent = (
  label: string,
  action: string,
  category: string,
  rest?: any,
) => ({
  event: 'user_interactions',
  user_event_label: label,
  user_event_action: action,
  user_event_category: category,
  ...rest,
});

export default userInteractionEvent;
