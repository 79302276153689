import { Dispatch, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { NakedButton, Icon } from '@qga/roo-ui/components';

interface DismissButtonProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const StyledIcon = styled(Icon)`
  color: ${themeGet('components.tooltip.text.color')};
`;

const DismissButton = ({ setIsOpen }: DismissButtonProps) => {
  const onClick = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <NakedButton onClick={onClick} data-testid="TOOLTIP_DISMISS_BUTTON">
      <StyledIcon name="closeRounded" />
    </NakedButton>
  );
};

export default DismissButton;
