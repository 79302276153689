import pluralize from 'pluralize';
import { pick } from 'ramda';

const getOccupantsItem = (count, type) => count && pluralize(type, count, true);

const getOccupantsSentence = (occupants) =>
  Object.keys(pick(['adults', 'children', 'infants'], occupants))
    .map((key) => getOccupantsItem(occupants[key], key))
    .filter((v) => !!v)
    .join(', ');

export default getOccupantsSentence;
