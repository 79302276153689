import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { themeGet } from 'styled-system';
import { HTMLProps, ReactNode, useRef, useState } from 'react';
import useBreakpoints, { isScreen } from '../../hooks/useBreakpoints';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  Placement,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { Box, Flex } from '@qga/roo-ui/components';
import TooltipText from './components/TooltipText';
import TooltipDismissButton from './components/TooltipDismissButton';

interface FloatProps {
  htmlProps?: HTMLProps<HTMLElement>;
  offset?: number;
  placement?: Placement;
}

interface TooltipProps {
  floatProps?: FloatProps;
  triggerProps?: HTMLProps<HTMLElement>;
  triggerElement: ReactNode;
  tooltipContent: ReactNode;
}

const TooltipContainer = styled(Box)`
  background-color: ${themeGet('components.tooltip.container.color')};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid ${themeGet('components.tooltip.container.borderColor')};
  border-radius: 4px;
`;

const Tooltip = ({
  floatProps = { offset: 10, placement: 'bottom-start' },
  triggerProps,
  triggerElement,
  tooltipContent,
}: TooltipProps) => {
  const theme = useTheme();

  const breakpoints = useBreakpoints();
  const smallScreen = isScreen(breakpoints)('xs', 'sm');

  const [isOpen, setIsOpen] = useState(false);

  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      flip(),
      arrow({
        element: arrowRef,
      }),
      offset(floatProps.offset),
    ],
    placement: floatProps.placement,
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);
  const focus = useFocus(context);
  const hover = useHover(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    focus,
    hover,
    role,
  ]);

  return (
    <Box>
      <Box
        ref={refs.setReference}
        {...getReferenceProps(triggerProps)}
        width="min-content"
        tabIndex="0"
      >
        {triggerElement}
      </Box>

      {isOpen && (
        <TooltipContainer
          padding="16px"
          width="295px"
          zIndex="10"
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps(floatProps.htmlProps)}
        >
          <Flex alignItems="start" gap="8px">
            {tooltipContent}
            {smallScreen && <TooltipDismissButton setIsOpen={setIsOpen} />}
          </Flex>
          <FloatingArrow
            ref={arrowRef}
            context={context}
            staticOffset={themeGet('components.tooltip.arrow.offset')({
              theme,
            })}
            width={themeGet('components.tooltip.arrow.width')({ theme })}
            height={themeGet('components.tooltip.arrow.height')({ theme })}
            fill={themeGet('components.tooltip.container.color')({ theme })}
            stroke={themeGet('components.tooltip.container.borderColor')({
              theme,
            })}
            strokeWidth={1}
          />
        </TooltipContainer>
      )}
    </Box>
  );
};

Tooltip.Text = TooltipText;

export default Tooltip;
