import PropTypes from 'prop-types';
import Image from '../shared/propTypes/Image';
import Geolocation from './Geolocation';

export default {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  description: PropTypes.string,
  ratingType: PropTypes.string,
  rating: PropTypes.number,
  checkInAfter: PropTypes.string,
  checkOutBefore: PropTypes.string,
  propertyPolicies: PropTypes.string,
  facilities: PropTypes.arrayOf(PropTypes.string),
  mainImage: PropTypes.shape(Image),
  address: PropTypes.string,
  geolocation: PropTypes.shape(Geolocation),
};
